import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const Architects = () => {
    return (
        <Layout>
            <Seo
                title="Architekten - BIKESAFE"
                description="Bike Safe bietet ein komplettes Produktangebot, das hohe Sicherheit mit ansprechender Ästhetik und skulpturhaftem Design verbindet – und das alles zu einem angemessenen Preis."
            />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                            Architekten
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" sm="12">
                        <h5 className="float-lg-left subheading-product uppercase">Qualität und Funktionalität</h5>
                    </Col>
                    <Col lg="6" sm="12">
                        <p className="flow-text">
                            Bike Safe bietet ein komplettes Produktangebot, das hohe Sicherheit mit ansprechender Ästhetik und skulpturhaftem Design verbindet – und das alles zu einem angemessenen Preis. Architekten und Stadtplaner finden bei Bike Safe Produkte, die ihren eigenen hohen Qualitäts-und Funktionalitätsstandards entsprechen und sich perfekt in die Stadtarchitektur einfügen.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="mt-5 mb-5 text-center">
                        <StaticImage placeholder="blurred" src="../images/projects.png" className="shadow fluid rounded " />
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" sm="12">
                        <h5 className="float-lg-left subheading-product uppercase">Interner CAD-Service</h5>
                    </Col>
                    <Col lg="6" sm="12">
                        <p className="flow-text">
                            Bike Safe bietet einen kompletten CAD-Design-Service an. Stephen, unser CAD-Designer, verfügt über umfangreiche Erfahrungen bei der Unterstützung von Architekten mit innovativen und einzigartigen Designs. Bei Fragen kontaktieren Sie uns bitte !
                        </p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default Architects;